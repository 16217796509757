import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import { DataFieldProps } from "atom/datafield/dataFieldComponents";
import DataField from "molecule/datafield/DataField";

function AutoFormDataField(componentProps: DataFieldProps & { name: string }) {
  const [autoFormFieldProps] = useAutoFormField<DataFieldProps>(
    componentProps.name,
    componentProps.type,
    componentProps.ref
  );
  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <DataField {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormDataField;
