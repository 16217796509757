import { useBloc } from "@blac/react";
import { useId } from "react";
import AutoFormBloc, {
  AutoFormControlsActions,
  AutoFormProps,
  FormSchemaType
} from "./AutoFormBloc";

export type AutoFormControlsResult<S extends FormSchemaType> = {
  props: AutoFormProps<S>;
} & AutoFormControlsActions<S>;

export default function useAutoFormControls<S extends FormSchemaType>(
  componentProps: AutoFormProps<S>
): AutoFormControlsResult<S> {
  const { contextId = useId() } = componentProps;

  const [, bloc] = useBloc(AutoFormBloc<S>, {
    id: contextId
    // props: componentProps
  });

  bloc.props = componentProps;

  return {
    props: {
      ...componentProps,
      contextId
    },
    dirty: bloc.state.dirty,
    getValues: bloc.getValues,
    setValue: bloc.setValue,
    setValues: bloc.setValues,
    reset: bloc.reset
  } satisfies AutoFormControlsResult<S>;
}
