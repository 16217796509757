import {
  CalendarDate,
  ZonedDateTime,
  getLocalTimeZone,
  now
} from "@internationalized/date";
import { Blac } from "blac-next";
import { format } from "date-fns";
import { PickerItemValidCheck } from "../atom/wheelpicker/PickerWheelItemBloc";
import { WheelPickerFieldItem } from "../atom/wheelpicker/WheelPicker";
import WheelPickerBloc from "../atom/wheelpicker/WheelPickerBloc";

export function pickerCurrentTime(): ZonedDateTime {
  const local = now(getLocalTimeZone());
  // return 10:56 AM
  // local = local.set({ hour: 12, minute: 4, second: 0, millisecond: 0 });
  return local;
}

/**
 * Generate a list of date options for the WheelPicker
 * @returns {WheelPickerFieldItem[]}
 * @example
 */
export function generateDateOptions(): WheelPickerFieldItem[] {
  const { year, month, day } = pickerCurrentTime();
  const calendar = new CalendarDate(year, month, day);
  const currentDaysInMonth = 30;
  return Array.from({ length: currentDaysInMonth * 2 }, (_, i) => {
    let c = calendar.copy();
    c = c.add({ days: -currentDaysInMonth + i });
    return {
      value: c.toString(),
      label:
        currentDaysInMonth === i
          ? "Today"
          : format(c.toDate(getLocalTimeZone()), "EEE MMM d")
    };
  });
}

/**
 * Generate a list of hour options for the WheelPicker
 * @returns {WheelPickerFieldItem[]}
 */
export function generateHourOptions(): WheelPickerFieldItem[] {
  const hours = 12;
  return Array.from({ length: hours }, (_, i) => {
    const hour = i + 1;
    return {
      value: hour.toString(),
      label: hour.toString().padStart(2, "0")
    };
  });
}

/**
 * Generate a list of minute options for the WheelPicker
 * @returns {WheelPickerFieldItem[]}
 */
export function generateMinuteOptions(): WheelPickerFieldItem[] {
  return Array.from({ length: 60 }, (_, i) => {
    const minute = i;
    return {
      value: minute.toString(),
      label: minute.toString().padStart(2, "0")
    };
  });
}

/**
 * Generate a list of time options for the WheelPicker (AM/PM)
 * @returns {WheelPickerFieldItem[]}
 */
export function generateAmPmOptions(): WheelPickerFieldItem[] {
  return [
    {
      value: "AM",
      label: "AM"
    },
    {
      value: "PM",
      label: "PM"
    }
  ];
}

export function fixAmPmDateHours(dateIsPm: boolean, hour: number): number {
  if (dateIsPm && hour === 12) {
    return 12;
  }
  if (!dateIsPm && hour === 12) {
    return 0;
  }

  if (dateIsPm) {
    return hour + 12;
  }

  return hour;
}

export const pickerValidateDate: PickerItemValidCheck = (item) => {
  if (!item.value) {
    return true;
  }
  const [year, month, day] = item.value.split("-").map(Number);
  const maxTime = pickerCurrentTime();
  const withDate = maxTime.set({
    year,
    month,
    day
  });
  return withDate.compare(maxTime) <= 0;
};

export const pickerValidateHour: PickerItemValidCheck = (item, pickerId) => {
  if (!item.value) {
    return false;
  }
  const { value: selectedDate } = Blac.instance.getBloc(
    WheelPickerBloc<ZonedDateTime>,
    { id: pickerId }
  ).state;
  if (!selectedDate) return false;

  const hour = Number(item.value);
  const isPm = selectedDate.hour >= 12;
  const maxTime = pickerCurrentTime();
  const withHour = selectedDate.set({
    hour: fixAmPmDateHours(isPm, hour)
  });

  return withHour.compare(maxTime) <= 0;
};

export const pickerValidateMinute: PickerItemValidCheck = (item, pickerId) => {
  if (!item.value) {
    return false;
  }
  const { value: selectedDate } = Blac.instance.getBloc(
    WheelPickerBloc<ZonedDateTime>,
    { id: pickerId }
  ).state;
  if (!selectedDate) return false;

  const minute = Number(item.value);
  const maxTime = pickerCurrentTime();
  const withMinute = selectedDate.set({
    minute
  });

  return withMinute.compare(maxTime) <= 0;
};

export const pickerValidateAmPm: PickerItemValidCheck = (item, pickerId) => {
  if (!item.value) {
    return true;
  }
  const { value: selectedDate } = Blac.instance.getBloc(
    WheelPickerBloc<ZonedDateTime>,
    { id: pickerId }
  ).state;
  if (!selectedDate) return false;

  const isPm = item.value === "PM";
  const hour = selectedDate.hour % 12;
  const maxTime = pickerCurrentTime();
  const withHour = selectedDate.set({
    hour: fixAmPmDateHours(isPm, hour)
  });

  return withHour.compare(maxTime) <= 0;
};

export type DatePickerParts = {
  date?: string;
  hour?: string;
  minute?: string;
  amPm?: string;
};
export function parseDatePickerParts(
  parts: DatePickerParts,
  pickerId: string
): ZonedDateTime {
  const { value } = Blac.instance.getBloc(WheelPickerBloc<ZonedDateTime>, {
    id: pickerId
  }).state;

  const selectedDate = value ?? pickerCurrentTime();

  let date = selectedDate;
  date = date.set({ second: 0, millisecond: 0 });

  if (parts.date) {
    const [year, month, day] = parts.date.split("-").map(Number);
    date = date.set({ year, month, day });
  }

  if (parts.hour) {
    const hour = Number(parts.hour);
    const isPm = parts.amPm === "PM" || selectedDate.hour >= 12;
    const fixedHour = fixAmPmDateHours(isPm, hour);
    date = date.set({ hour: fixedHour });
  }

  if (parts.minute) {
    const minute = Number(parts.minute);
    date = date.set({ minute });
  }

  if (parts.amPm) {
    const isPm = parts.amPm === "PM";
    const hour12 = date.hour % 12;
    const fixedHour = fixAmPmDateHours(isPm, hour12);
    date = date.set({ hour: fixedHour });
  }

  return date;
}
