import { z } from "zod";
import type { ZonedDateTime } from "@internationalized/date";
import { parseZonedDateTime } from "@internationalized/date";
import { pickerCurrentTime } from "@9amhealth/shared";
import translate from "src/lib/translate";

export const validateDate = z.custom<ZonedDateTime>().refine(
  (v) => {
    if (!v) return false;
    const asZoned = parseZonedDateTime(String(v));

    const now = pickerCurrentTime();

    if (asZoned.year < now.year - 3) return false;

    return asZoned.compare(now) <= 0;
  },
  { message: translate("error_validate_date_min_three_years") }
);
