import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import DataFieldDate, {
  DataFieldDateProps
} from "molecule/datafield/DataFieldDate";

function AutoFormDataFieldDate(props: DataFieldDateProps & { name: string }) {
  const [autoFormFieldProps] = useAutoFormField<DataFieldDateProps>(
    props.name,
    "date",
    props.ref
  );
  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <DataFieldDate {...props} {...autoFormFieldProps} />;
}

export default AutoFormDataFieldDate;
