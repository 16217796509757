import { useBloc } from "@blac/react";
import React, { useEffect, useId, useRef } from "react";
import WheelPickerBloc from "./WheelPickerBloc";
import { WheelPickerContextProvider } from "./WheelPickerContext";
import { Wrap } from "./WheelPickerStyled";

export type WheelPickerFieldItem = {
  label: string;
  value: string;
};

export type WheelPickerField = {
  name: string;
  items: WheelPickerFieldItem[];
  value: string;
  onChange?: (value: string) => void;
  loop?: boolean;
  alignContent?: "left" | "right" | "center";
  width?: string;
  style?: React.CSSProperties;
};

export default function WheelPicker<T>({
  children,
  onChange,
  pickerId,
  initialValue,
  parseValues
}: {
  children: React.ReactNode;
  onChange?: (data: Record<string, string>) => void;
  pickerId?: string;
  initialValue: T;
  parseValues: (itemValues: Record<string, string>) => T | null;
}) {
  const contextId = pickerId ?? useId();
  const wrapRef = useRef<HTMLDivElement>(null);
  const [, { setEmblaRef }] = useBloc(WheelPickerBloc<T>, {
    id: contextId,
    props: {
      onChange,
      initialValue,
      parseValues
    }
  });

  useEffect(() => {
    if (wrapRef.current) {
      setEmblaRef(wrapRef.current);
    }
  }, [wrapRef.current]);

  return (
    <WheelPickerContextProvider value={{ id: contextId }}>
      <Wrap className="embla" ref={wrapRef}>
        {children}
      </Wrap>
    </WheelPickerContextProvider>
  );
}
