import { Cubit } from "blac-next";

interface WheelPickerBlocProps<T> {
  onChange?: (data: Record<string, string>) => void;
  parseValues?: (itemValues: Record<string, string>) => T | null;
  initialValue?: T;
}

export default class WheelPickerBloc<T> extends Cubit<
  { value: T | null },
  WheelPickerBlocProps<T>
> {
  emblaRef?: HTMLElement;
  allValues: Record<string, string> = {};
  onChange?: (data: Record<string, string>) => void;
  parseValues: (itemValues: Record<string, string>) => T | null;

  constructor(props: WheelPickerBlocProps<T> = {}) {
    super({ value: props.initialValue ?? null });
    this.onChange = props.onChange;
    this.parseValues = props.parseValues ?? (() => null);
  }

  setEmblaRef = (emblaRef: HTMLElement) => {
    this.emblaRef = emblaRef;
    emblaRef.dataset.connected = "true";
    const listener = (
      e: CustomEvent<{
        name?: string;
        value?: string;
      }>
    ) => {
      e.preventDefault();
      const name = e.detail.name;
      const value = e.detail.value;
      const newValues = { ...this.allValues };
      if (typeof name === "string" && typeof value === "string") {
        newValues[name] = value;
      }

      const changed =
        JSON.stringify(newValues) !== JSON.stringify(this.allValues);

      if (this.onChange && changed) {
        this.allValues = { ...this.allValues, ...newValues };
        this.onChange(this.allValues);
        this.patch({ value: this.parseValues(newValues) });
      }
    };

    emblaRef.addEventListener(
      "wheel:selected" as keyof HTMLElementEventMap,
      listener as EventListenerOrEventListenerObject
    );

    // prevent scrolling page when touchmove on mobile
    emblaRef.addEventListener("touchmove", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    // prevent scrolling page when mousewheel on desktop
    emblaRef.addEventListener("wheel", (e) => {
      e.preventDefault();
    });

    return () => {
      emblaRef?.removeEventListener(
        "wheel:selected" as keyof HTMLElementEventMap,
        listener as EventListenerOrEventListenerObject
      );
    };
  };
}
