import {
  generateAmPmOptions,
  generateDateOptions,
  generateHourOptions,
  generateMinuteOptions,
  parseDatePickerParts,
  pickerCurrentTime,
  PickerItem,
  pickerValidateAmPm,
  pickerValidateDate,
  pickerValidateHour,
  pickerValidateMinute,
  WheelPicker
} from "@9amhealth/shared";
import type { ZonedDateTime } from "@internationalized/date";
import { CalendarDate } from "@internationalized/date";
import type { FC, ReactNode } from "react";
import React, { useEffect } from "react";

const EventTrap: FC<{ children: ReactNode }> = ({ children }) => {
  const elRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // prevent all bubbling events
    const el = elRef.current;
    if (!el) return;
    const prevent = (e: Event) => {
      e.stopPropagation();
    };

    el.addEventListener("click", prevent);
    el.addEventListener("mousedown", prevent);
    el.addEventListener("mouseup", prevent);
    el.addEventListener("mousemove", prevent);
    el.addEventListener("touchstart", prevent);
    el.addEventListener("touchmove", prevent);
    el.addEventListener("touchend", prevent);
    el.addEventListener("touchcancel", prevent);
  }, [elRef.current]);

  return (
    <div
      ref={elRef}
      style={{ overflow: "hidden", width: "100%", height: "100%" }}
    >
      {children}
    </div>
  );
};

interface Props {
  onDateSelected: (date: ZonedDateTime) => void;
  granularity?: "day" | "minute";
  initialDate?: ZonedDateTime;
}

export const DateWheelPicker: FC<Props> = ({
  onDateSelected,
  granularity = "minute",
  initialDate
}) => {
  const now = pickerCurrentTime();
  const startAtDate = initialDate ?? now;
  const [selectedDate, setSelectedDate] =
    React.useState<ZonedDateTime>(startAtDate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [val, setVal] = React.useState<{
    date?: string;
    hour?: string;
    minute?: string;
    amPm?: string;
    dateOb?: Date;
  }>({});

  const dateValue = new CalendarDate(
    startAtDate.year,
    startAtDate.month,
    startAtDate.day
  ).toString();
  const minuteValue = startAtDate.minute.toString();
  const amPmValue = startAtDate.hour < 12 ? "AM" : "PM";
  const hourValue = `${startAtDate.hour % 12}`;

  const dateItems = React.useMemo(generateDateOptions, []);
  const hourItems = React.useMemo(() => generateHourOptions(), []);
  const minuteItems = React.useMemo(generateMinuteOptions, []);
  const amPmItems = React.useMemo(generateAmPmOptions, []);

  const pickerId = "datePicker";
  const handleChange = (data: typeof val) => {
    const parsed = parseDatePickerParts(data, pickerId);
    if (parsed) {
      setSelectedDate(parsed);
      setVal(data);
      onDateSelected(parsed);
    }
  };

  return (
    <EventTrap>
      <WheelPicker
        onChange={handleChange}
        initialValue={selectedDate}
        pickerId={pickerId}
        parseValues={(values) => parseDatePickerParts(values, pickerId)}
      >
        <PickerItem
          name="date"
          value={dateValue}
          perspective="left"
          alignContent={granularity === "minute" ? "right" : "center"}
          slides={dateItems}
          validCheck={pickerValidateDate}
        />
        {granularity === "minute" && (
          <PickerItem
            name="hour"
            slides={hourItems}
            value={hourValue}
            alignContent="right"
            validCheck={pickerValidateHour}
            loop
          />
        )}
        {granularity === "minute" && (
          <PickerItem
            name="minute"
            value={minuteValue}
            slides={minuteItems}
            alignContent="left"
            validCheck={pickerValidateMinute}
          />
        )}
        {granularity === "minute" && (
          <PickerItem
            name="amPm"
            value={amPmValue}
            slides={amPmItems}
            validCheck={pickerValidateAmPm}
            alignContent="left"
          />
        )}
      </WheelPicker>
    </EventTrap>
  );
};
