import styled from "@emotion/styled";
import type { ZonedDateTime } from "@internationalized/date";
import type { FC } from "react";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { DateWheelPicker } from "src/ui/components/DateWheelPicker/DateWheelPicker";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
`;

export const Bar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: end;
  align-items: center;
  background: var(--color-cream-dark);
  border: 1px solid var(--color-gray-light);
  border-left: 0;
  border-right: 0;
  padding: 10px 20px;
`;

export const Button = styled.button`
  color: var(--color-charcoal);
  background: transparent;
  cursor: pointer;
  padding: 0.6rem;
  font-weight: 700;
  font-size: 14px;
`;

export const PickerFrame = styled.div`
  height: 240px;
  width: min(100vw, 488px);
  margin: 0 auto;
  overflow: hidden;
`;

interface Props {
  onFinish: () => void;
  onChange: (dateTime: ZonedDateTime) => void;
  title?: string;
  granularity?: "day" | "minute";
  initialDate?: ZonedDateTime;
}

/*
 * Component for displaying a time picker designed for mobile devices. Own implementation of ionic inline picker.
 */
export const TimePickerSheet: FC<Props> = ({
  onFinish,
  onChange,
  title,
  granularity = "minute",
  initialDate
}) => {
  useEffect(() => {
    // hide keyboard when time picker is opened
    const activeElement = document.activeElement as HTMLElement | undefined;
    activeElement?.blur();
  }, []);

  return (
    <>
      <Overlay />
      <div>
        <Wrapper>
          <Bar>
            <div />

            {title}

            <div>
              <Button
                onClick={() => {
                  onFinish();
                }}
              >
                Done
              </Button>
            </div>
          </Bar>

          <PickerFrame>
            <DateWheelPicker
              granularity={granularity}
              initialDate={initialDate}
              onDateSelected={(value: ZonedDateTime) => {
                onChange(value);
              }}
            />
          </PickerFrame>
        </Wrapper>
      </div>
    </>
  );
};

export const handleOpenTimePicker = (options: {
  onFinish: (dateTime: ZonedDateTime) => void;
  title?: string;
  granularity?: Props["granularity"];
  initialDate?: ZonedDateTime;
}) => {
  const { onFinish, title, granularity, initialDate } = options;
  const timePicker = document.createElement("div");
  document.body.appendChild(timePicker);
  const root = createRoot(timePicker);

  const close = () => {
    root.unmount();
    document.body.removeChild(timePicker);
  };

  const handleFinish = () => {
    close();
  };

  const handleSelectChange = (dateTime: ZonedDateTime) => {
    onFinish(dateTime);
  };

  root.render(
    <TimePickerSheet
      onFinish={handleFinish}
      onChange={handleSelectChange}
      title={title}
      granularity={granularity}
      initialDate={initialDate}
    />
  );
};
